import { Injectable } from '@angular/core';

export interface DateInterval {
  from: Date | number | null;
  to: Date | number | null;
}

/**
 * Two DateIntervals that have overlapping dates.
 */
export type Overlap = [DateInterval, DateInterval];

/**
 * Date utilities.
 */
@Injectable({ providedIn: 'root' })
export class DateUtils {
  MS_PER_DAY = 86400000;
  MS_PER_HOUR = 3600000;
  EPOCH_DAY = 4; // January 1, 1970 was a Thursday

  /**
   * Checks whether a date is inside another date interval.
   *
   * @param date to check if it's inside an interval.
   * @param interval interval to check against.
   * @param allowUnion Consider ending and starting on the same date as an overlap.
   */
  isDateWithinInterval(date: Date, interval: DateInterval, allowUnion = false): boolean {
    const timeStamp = date.getTime();
    const fromTime = DateUtils.toInfinityOrFixedFromDate(interval.from);
    const toTime = DateUtils.toInfinityOrFixedToDate(interval.to);

    if (allowUnion) {
      return timeStamp >= fromTime && timeStamp <= toTime;
    }

    return timeStamp > fromTime && timeStamp < toTime;
  }

  /**
   * Checks if two date intervals overlap.
   *
   * @param dateInterval1 The first date interval.
   * @param dateInterval2 The second date interval.
   * @param allowUnion Consider ending and starting on the same date as an overlap.
   * @returns True if the intervals overlap, false otherwise.
   */
  isOverlapping(dateInterval1: DateInterval, dateInterval2: DateInterval, allowUnion = false): boolean {
    const safeFrom1 = DateUtils.toInfinityOrFixedFromDate(dateInterval1.from);
    const safeTo1 = DateUtils.toInfinityOrFixedToDate(dateInterval1.to);
    const safeFrom2 = DateUtils.toInfinityOrFixedFromDate(dateInterval2.from);
    const safeTo2 = DateUtils.toInfinityOrFixedToDate(dateInterval2.to);

    if (allowUnion) {
      return safeFrom1 < safeTo2 && safeFrom2 < safeTo1;
    }

    return safeFrom1 <= safeTo2 && safeFrom2 <= safeTo1;
  }

  /**
   * Checks a list of intervals for overlapping intervals.
   *
   * @param dateIntervals List to check for overlapping intervals.
   * @param allowUnion Consider ending and starting on the same date as an overlap.
   * @returns A list of intervals that overlap.
   */
  findOverlappingIntervals(dateIntervals: DateInterval[], allowUnion = false): Overlap[] {
    const overlaps: Overlap[] = [];

    for (let i = 0; i < dateIntervals.length; i++) {
      for (let j = i + 1; j < dateIntervals.length; j++) {
        if (this.isOverlapping(dateIntervals[i], dateIntervals[j], allowUnion)) {
          overlaps.push([dateIntervals[i], dateIntervals[j]]);
        }
      }
    }

    return overlaps;
  }

  /**
   * Convenience method to create an interval from two strings.
   */
  createInterval(from: string | null, to: string | null): DateInterval | null {
    const dateInterval: DateInterval = {
      from: from ? new Date(from) : null,
      to: to ? new Date(to) : null,
    };

    if (!this.isValidInterval(dateInterval)) {
      return null;
    }

    return dateInterval;
  }

  /**
   * Validates that [from] comes before [to].
   *
   * @param dateInterval The date interval to validate.
   * @param allowUnion Consider ending and starting on the same date as an overlap.
   */
  isValidInterval(dateInterval: DateInterval, allowUnion = false): boolean {
    const from = DateUtils.toInfinityOrFixedFromDate(dateInterval.from);
    const to = DateUtils.toInfinityOrFixedToDate(dateInterval.to);

    if (allowUnion) {
      return from <= to;
    }

    return from < to;
  }

  /**
   * Convenience method to turn ms into a Date.
   */
  msToDate = DateUtils.valueToDate;

  /**
   * Convenience method to try and turn a value into a Date.
   * Method is static to allow usage outside of injection context.
   *
   * If the given argument is already a Date, it will be returned as is.
   * If the given argument is a string, it will try to be parsed into a Date.
   * If the value is null or an empty string, the returned value will be null.
   *
   * This saves extra checks for the caller.
   */
  static valueToDate(value: number | Date | string): Date {
    if (value == '') return null as unknown as Date; // Always return as Date to avoid typescript boilerplate for the caller of this method
    if (value == null || value instanceof Date) return value as Date;
    return new Date(value);
  }

  /**
   * Convenience method to turn a Date into ms.
   */
  dateToMs = DateUtils.valueToMs;

  /**
   * Convenience method to try and turn a value into millis.
   * Method is static to allow usage outside of injection context.
   *
   * If the given argument is already a number, it will be returned as is.
   * If the given argument is a string, it will try to be parsed into a number.
   * If the value is null or an empty string, the returned value will be null.
   *
   * This saves extra checks for the caller when conversion is needed.
   */
  static valueToMs(value: Date | number | string): number {
    if (typeof value === 'string') {
      if (value == '') return null as unknown as number; // Always return as number to avoid typescript boilerplate for the caller of this method
      value = new Date(value); // Try to parse the string
    }
    if (value == null || typeof value === 'number') return value as number;
    return value.getTime();
  }

  /**
   * Converts a date or number (timestamp) to a number (timestamp).
   */
  private static toTimestamp(date: Date | number) {
    return date instanceof Date ? date.getTime() : date;
  }

  private static toInfinityOrFixedToDate(to: Date | number | null) {
    return to ? DateUtils.toTimestamp(to) : Number.POSITIVE_INFINITY;
  }

  private static toInfinityOrFixedFromDate(from: Date | number | null) {
    return from ? DateUtils.toTimestamp(from) : Number.NEGATIVE_INFINITY;
  }

  /**
   * Checks if a date is a weekday (Monday to Friday).
   */
  isWeekday(date: Date | number): boolean {
    const daysSinceEpoch = Math.floor(this.dateToMs(date) / this.MS_PER_DAY);
    const dayOfWeek = (daysSinceEpoch + this.EPOCH_DAY) % 7;
    return dayOfWeek >= 1 && dayOfWeek <= 5;
  }

  /**
   * Checks if a date is a weekend (Saturday or Sunday).
   */
  isWeekend(date: Date | number): boolean {
    const daysSinceEpoch = Math.floor(this.dateToMs(date) / this.MS_PER_DAY);
    const dayOfWeek = (daysSinceEpoch + this.EPOCH_DAY) % 7;
    return dayOfWeek == 6 || dayOfWeek == 0;
  }

  /**
   * Checks if a date is after hours (20:00 to 06:00).
   */
  isAfterHours(date: Date | number): boolean {
    const hours = Math.floor((this.dateToMs(date) % this.MS_PER_DAY) / this.MS_PER_HOUR); // Calculate the hour of the day in UTC
    return hours >= 20 || hours < 6;
  }
}
